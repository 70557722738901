/* eslint-disable @typescript-eslint/no-explicit-any */
// import {  useState } from "react";

import { useState } from "react";
import style from "./Styles/mainHeader.module.css";
import { isMobile } from "react-device-detect";
import MainSlider from "./MainSlider";
import { componentNames } from "../../Utils/Constants";
import MainHeader from "./MainHeader";

export default function HeaderAndSlider(props: any) {
  const [hideSlider, setHideSlider] = useState(isMobile ? true : false);

  /**
   *
   */
  function setSlider() {
    setHideSlider(!hideSlider);
  }

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div className={style.mainHeader} id="headerWrapper">
        <MainHeader
          key={hideSlider}
          handleFilter={setSlider}
          hideSlider={hideSlider}
          title={props.title}
          name={componentNames.MainHeader}
        ></MainHeader>
      </div>
      <div id="allContentWrapper">
        <div
          className={hideSlider ? style.mainSliderHide : style.mainSlider}
          id="sliderWrapper"
        >
          <MainSlider
            key={hideSlider}
            open={hideSlider}
            name={"MainSlider"}
            handleFilter={setSlider}
            info={[]}
          ></MainSlider>
        </div>
        <div id="contentWrapper">{props.component}</div>
      </div>
    </div>
  );
}
