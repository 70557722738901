import * as Yup from "yup";
import { Vocabulary } from "../Utils/Vocabulary";

export const CategoryModel = Yup.object().shape({
    _id: Yup.string(),
    name: Yup.string().required(Vocabulary.required),
    description: Yup.string().required(Vocabulary.required),
    images: Yup.array(),
    category: Yup.string().nullable().default(null),
    files: Yup.array()
  });