const language: any = {
  english: {
  // Common
  create: "Create",
  edit: "Edit",
  view: "View",
  delete: "Delete",
  options: "Options",
  name: "Name",
  description: "Description",
  images: "Images",
  uploadImages: "Upload images",
  none: "None",
  finish: "Finish",
  cancel: "Cancel",
  attachFiles: "Attach files",
  attachingFilesNumberError: "You can only attach",
  generalAttachmentError: "An error occurred while attaching the files!",
  dragAndDrop: "Drag and drop your files here or click to select files",
  save: "Save",
  settings: "Settings",
  // Products
  product: "Product",
  products: "Products",
  productsList: "Products list",
  productName: "Product Name",
  category: "Category",
  price: "Price",
  priceNoVAT: "Price (no VAT)",
  tax: "Tax",
  totalPrice: "Total price",


  // Categories
  categories: "Categories",
  categoriesList: "Categories list",
  parentCategory: "Parent Category",

  // Clients
  client: "Client",
  clients: "Clients",
  customersList: "Customers list",
  firstName: "First name",
  lastName: "Last name",
  email: "Email",
  phone: "Phone",
  address: "Address",

  // Orders
  order: "Order",
  orders: "Orders",
  ordersList: "Orders list",
  customer: "Customer",
  status: "Status",
  total: "Total",

  // Product Lines
  productLine: "Product Line",
  productLines: "Product Lines",
  linesList: "Lines list",
  quantity: "Quantity",

  // Users
  admin: "Orders Manager",
  user: "User",
  users: "Users",
  usersList: "Users list",
  userName: "Username",
  roles: "Roles",
  password: "Password",
  emailRequired: "Email is required",
  passwordRequired: "Password is required",
  rolesRequired: "Role is required",

  // Settings
  option: "Option",
  settingsOption: "Settings option",
  value: "Value",
  values: "Values",
  settingsList: "Settings list",

  // Statuses
  pending: "Pending",
  processing: "Processing",
  completed: "Completed",

  // Error messages
  systemError: "System error! Contact the administrator!",
  getDataError: "Error getting data for",
  deleteDataError: "Error deleting data for",
  createDataError: "Error creating data for",
  updateDataError: "Error updating data for",
  dataIsRequired: "Data is required!",

  // Success messages
  deleteDataSuccess: "Data has been deleted successfully for",
  createDataSuccess: "Data has been created successfully for",
  updateDataSuccess: "Data has been updated successfully for",
  getDataSuccess: "Data has been retrieved successfully for",

  login: "Login",
  username: "Username",
  addNewCategory: "Add new category",
  imageDoesNotExist: "Image does not exist!",
  deleteCategory: "Delete category",
  deleteCategoryMessage: "Are you sure you want to delete this category?",
  deleteOrderMessage: "Are you sure you want to delete this order?",
  deleteOrder: "Delete order",
  addNewProduct: "Add product",
  addNewOrder: "Add order",
  editOrder: "Edit order",
  discount: "Discount",
  orderStatus: "Order status",
  courier: "Courier",
  phoneNumber: "Phone number",
  discountUser: "Discount user",
  updatedAt: "Updated at",
  addNewUser: "Add user",
  deleteDetails: "Are you sure you want to delete this row ?",
  required: "This field is required",
  },
  romanian: {
    // Common
    create: "Creare",
    edit: "Editează",
    view: "Vizualizează",
    delete: "Șterge",
    options: "Opțiuni",
    name: "Nume",
    description: "Descriere",
    images: "Imagini",
    uploadImages: "Trimite imagini",
    none: "Niciunul",
    finish: "Finalizează",
    cancel: "Anulează  ",
    attachFiles: "Atașează fișiere",
    attachingFilesNumberError: "Nu se mai pot atașa imagini!",
    generalAttachmentError: "Eroare la atașarea fișierelor!",
    dragAndDrop: "Trage și lipește imaginea sau apasă butonul pentru a selecta imaginea",
    save: "Salvează ",
    settings: "Setări",
    // Products
    product: "Produs",
    products: "Produse",
    productsList: "Listă produse",
    productName: "Nume produs",
    category: "Categorie",
    price: "Preț",
    priceNoVAT: "Preț (fără TVA)",
    tax: "Taxă",
    totalPrice: "Total",
    // Categories
    categories: "Categorii",
    categoriesList: "Listă categorii",
    parentCategory: "Categorie părinte",
    // Clients
    client: "Client",
    clients: "Clienți",
    customersList: "Listă clienți",
    firstName: "Nume",
    lastName: "Prenume",  
    email: "Email",
    phone: "Telefon",
    address: "Adresă",
    // Orders
    order: "Comandă",
    orders: "Comenzi",
    ordersList: "Listă comenzi",
    customer: "Client",
    status: "Status",
    total: "Total",
    // Product Lines
    productLine: "Linie produs",
    productLines: "Linii produse",
    linesList: "Listă linii produse",
    quantity: "Cantitate",
    // Users
    admin: "Manager comenzi",
    user: "Utilizator",
    users: "Utilizatori",
    usersList: "Listă utilizatori",
    userName: "Utilizator",
    roles: "Roluri",
    password: "Parolă",
    emailRequired: "Email-ul este obligatoriu",
    passwordRequired: "Parola este obligatorie",
    rolesRequired: "Rolul este obligatoriu",
    // Settings
    option: "Opțiune",  
    settingsOption: "Opțiuni setări",
    value: "Valoare",
    values: "Valori",
    settingsList: "Listă setări",
    // Statuses
    pending: "În așteptare",
    processing: "În procesare",
    completed: "Completat",
    // Error messages
    systemError: "Eroare sistem! Contactează  administratorul!",
    getDataError: "Eroare la preluarea datelor pentru", 
    deleteDataError: "Eroare la ștergerea datelor pentru",
    createDataError: "Eroare la crearea datelor pentru",
    updateDataError: "Eroare la actualizarea datelor pentru",
    dataIsRequired: "Datele sunt obligatorii!",
    // Success messages
    deleteDataSuccess: "Datele au fost șterse cu succes pentru",
    createDataSuccess: "Datele au fost create cu succes pentru",
    updateDataSuccess: "Datele au fost actualizate cu succes pentru",
    getDataSuccess: "Datele au fost preluate cu succes pentru",
    login: "Autentificare",
    username: "Nume utilizator",
    addNewCategory: "Adauga categorie",
    imageDoesNotExist: "Nu exista imagini pentru aceasta categorie.",
    deleteCategory: "Șterge categorie",
    deleteCategoryMessage: "Sunteți sigur că doriți să ștergeți această categorie?",
    deleteOrderMessage: "Sunteți sigur că doriți să ștergeți această comanda?",
    deketeOrder: "Șterge comanda",
    addNewProduct: "Adauga produs",
    addNewOrder: "Adaugă comandă",
    editOrder: "Editează comanda",
    discount: "Reducere",
    orderStatus: "Status comandă",
    courier: "Courier",
    phoneNumber: "Telefon",
    discountUser: "Reducere utilizator",
    orderStatuses: "Status comenzi",
    updatedAt: "Actualizat la",
    addNewUser: "Adaugă utilizator",
    deleteDetails: "Sunteți sigur că doriți să ștergeți acest rand ?",
    required: "Acest câmp este obligatoriu",
    confirmPassword: "Confirmare parolă",
    deleteUser: "Șterge utilizator",
    deleteUserMessage: "Sunteți sigur că doriți să ștergeți acest utilizator?",
    editUser: "Editeaza utilizatorul",
  }
};

const userLang = localStorage.getItem("language") || "romanian";

const Vocabulary = language[userLang];

export { Vocabulary };
