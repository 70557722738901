import { Vocabulary } from "./Vocabulary";
import {
  MAIN_SLIDER_DASHBOARD,
  ROUTER_DASHBOARD,
} from "../Permissions/dashboard";
import { MAIN_SLIDER_USERS } from "../Permissions/users";
import { isMobile } from "react-device-detect";

export const HttpStatusCode = {
  OK: 200,
  Created: 201,
  Accepted: 202,
  BadRequest: 400,
  Unauthorized: 401,
  Forbidden: 403,
  NotFound: 404,
  RequestTimeout: 408,
  InternalServerError: 500,
  NotImplemented: 501,
  UnprocessableEntity: 422,
};

export const HttpVerbs = {
  post: "post",
  get: "get",
  put: "put",
  patch: "patch",
  delete: "delete",
};

export const userExcelHeader = ["Nume", "Prenume", "Email", "Departament"];

export const departmentsExcelHeader = ["Nume"];

export const tasksExcelHeader = [
  "Departament",
  "Task",
  "Tip",
  "Periodicitate",
  "Prioritate",
  "Start",
  "Stop",
  "Stare",
  "Buget",
  "Echipamente",
  "Personal",
  "Progres",
  "Responsabil",
  "Observatii",
];

export const ROLES = {
  ROLE_ADMINISTRATOR: "ROLE_ADMINISTRATOR",
  ROLE_DEPARTMENT_HEAD: "ROLE_DEPARTMENT_HEAD",
  ROLE_PROJECT_MANAGER: "ROLE_PROJECT_MANAGER",
  ROLE_USER: "ROLE_USER",
  ROLE_STORE_HEAD: "ROLE_STORE_HEAD",
  ROLE_STORE_MANAGER: "ROLE_STORE_MANAGER",
};

export const euFormat = "DD.MM.YYYY";
export const euFormatForTime = "HH:mm";
export const euFormatForDateTime = "DD.MM.YYYY HH:mm";
export const euFormatForDateTimeWithSeconds = "DD.MM.YYYY HH:mm:ss";
export const dayPilotFormatWithTime = "YYYY-MM-DDTHH:mm:ss";
export const dayPilotFormat = "YYYY-MM-DD";

export const toggleTimeTracking = [
  {
    name: Vocabulary.takePredefinedCost,
    value: "takePredefinedCost",
  },
  {
    name: Vocabulary.timeTracking,
    value: "timeTracking",
  },
];

export const roles = {
  admin: "admin",
  user: "user",
  reporting: "reporting",
  technician: "technician",
};
export const numberOfDigits = 2;

export const PermissionsUI: { [key: string]: any } = {
  admin: {
    LocalRouter: ROUTER_DASHBOARD,
    MainSlider: {
      info: MAIN_SLIDER_DASHBOARD,
    },
    Dashboard: {
      info: MAIN_SLIDER_DASHBOARD,
    },
    // Users: {
    //   info: MAIN_SLIDER_USERS,
    // },
  },
  courier: {
    LocalRouter: ROUTER_DASHBOARD,
    MainSlider: {
      info: MAIN_SLIDER_DASHBOARD,
    },
    Dashboard: {
      info: MAIN_SLIDER_DASHBOARD,
    },
    Users: {
      info: MAIN_SLIDER_USERS,
    },
  },
};

//NAMES FOR EACH COMPONENT
export const componentNames = {
  LocalRouter: "LocalRouter",
  DinamicTabs: "DinamicTabs",
  MainHeader: "MainHeader",
  MainSlider: "MainSlider",
  LoginPage: "LoginPage",
};

export const portraitLayout = window.matchMedia("(orientation: portrait)");

export const tableLayoutOnMobile =
  isMobile && portraitLayout.matches ? "simple" : ("standard" as any);

export const ordersStatusesLegend = [
  {
    key: 0,
    id: 0,
    nume: "Confirmat",
    culoare: "#a389d1",
    tip_status: "Confirmat",
  },
  {
    key: 0,
    id: 0,
    nume: "Pregătire",
    culoare: "#FFA726",
    tip_status: "Pregătire",
  },
  {
    key: 0,
    id: 0,
    nume: "Livrare",
    culoare: "#26C6DA",
    tip_status: "Ieșit pentru livrare",
  },
  {
    key: 0,
    id: 0,
    nume: "Livrat",
    culoare: "#66BB6A",
    tip_status: "Livrat",
  },
  {
    key: 0,
    id: 0,
    nume: "Anulat",
    culoare: "#B0BEC5",
    tip_status: "Anulat",
  },
  {
    key: 0,
    id: 0,
    nume: "A eșuat",
    culoare: "#EF5350",
    tip_status: "A eșuat",
  },
];