import { Autocomplete, TextField } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Controller } from "react-hook-form";
import { Vocabulary } from "../../Utils/Vocabulary";
import AddAndShowImages from "../Generic/AddAndShowImages";
import { FormEventHandler } from "react";

interface AddCategoryProps {
  control: any;
  handleSubmit: (payload: any) => FormEventHandler<HTMLFormElement>;
  handleSubmitData: () => void;
  formData: any;
  handleDeleteImages: (images: any, files: any) => void;
  handleChangeFiles: (files: any) => void;
  displayDropzoneImages: (images: any) => void;
  categories: any;
  mode: "create" | "edit" | "view" | "remove";
}
export default function AddCategory(props: AddCategoryProps) {
  const {
    categories,
    formData,
    control,
    handleSubmit,
    handleSubmitData,
    handleDeleteImages,
    handleChangeFiles,
    displayDropzoneImages,
    mode,
  } = props;

  return (
    <Grid sx={{ flex: 2, mt: 4, mb: 2 }}>
      <form
        onSubmit={handleSubmit(handleSubmitData)}
        method="get"
        id="checkUserData"
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <Controller
          name="name"
          defaultValue=""
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <TextField
              onChange={onChange}
              fullWidth
              style={{
                marginBottom: 15,
              }}
              value={value}
              error={!!error}
              helperText={error?.message}
              id="name"
              label={Vocabulary.name}
              size="small"
              variant="outlined"
            />
          )}
        />
        <Controller
          name="description"
          defaultValue=""
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <TextField
              onChange={onChange}
              fullWidth
              style={{
                marginBottom: 15,
              }}
              value={value}
              error={!!error}
              helperText={error?.message}
              id="description"
              label={Vocabulary.description}
              size="small"
              variant="outlined"
            />
          )}
        />
        <Controller
          name="category"
          control={control}
          defaultValue={null}
          render={({
            field: { ref, onChange, value, ...field },
            fieldState: { error },
          }) => (
            <Autocomplete
              freeSolo={false}
              style={{
                marginBottom: 15,
              }}
              {...field}
              size="small"
              disableClearable
              disablePortal
              getOptionLabel={(option: any) => option.name || ""}
              id="category"
              onChange={(event, newValue: any) => {
                onChange(newValue ? newValue._id : "");
              }}
              value={
                categories?.find((option: any) => option?._id === value) ||
                null
              }
              options={categories}
              renderInput={(params) => (
                <TextField
                  error={!!error}
                  helperText={error?.message}
                  label={Vocabulary.category}
                  name="category"
                  type="search"
                  inputRef={ref}
                  {...params}
                />
              )}
            />
          )}
        />
        <AddAndShowImages
          images={formData.images || []}
          files={formData.files || []}
          checkedImages={formData.images || []}
          isSmall={true}
          handleDeleteImages={handleDeleteImages}
          handleChangeFiles={handleChangeFiles}
          displayDropzoneImages={displayDropzoneImages}
          mode={mode}
        />
      </form>
    </Grid>
  );
}
