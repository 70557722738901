import * as Yup from "yup";
import { Vocabulary } from "../Utils/Vocabulary";

export const ProductModel = Yup.object().shape({
    _id: Yup.string(),
    name: Yup.string().required(Vocabulary.required),
    description: Yup.string().required(Vocabulary.required),
    priceNoVAT: Yup.number().required(Vocabulary.required),
    images: Yup.array(),
    category: Yup.string(),
    files: Yup.array()
  });