import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider, createTheme } from "@mui/material";
import LocalRouter from "./Utils/LocalRouter";
import { componentNames } from "./Utils/Constants";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./components/Login";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GlobalProvider } from "./Context/GlobalContext";

let theme = createTheme({
  palette: {
    primary: {
      main: "#2b5231",
    },
    secondary: {
      main: "#4d3a29",
    },
    info: {
      main: "#f25",
    },
  },
});

theme = createTheme(theme, {
  palette: {
    info: {
      main: theme.palette.secondary.main,
    },
  },
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
      <ThemeProvider theme={theme}>
        <ToastContainer />
        {!window.localStorage.getItem("roles") ? (
          <BrowserRouter>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/" element={<Login />} />
            </Routes>
          </BrowserRouter>
        ) : (
          <GlobalProvider>
            <div style={{ overflowY: "hidden" }}>
              <LocalRouter name={componentNames.LocalRouter}></LocalRouter>
            </div>
          </GlobalProvider>
        )}
      </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
