import { useEffect, useState } from "react";
import moment from "moment";
import hash from "object-hash";
import { PermissionsUI } from "./Constants";

/**
 * Hook that checks the role of the logged user and renders the component based on the role
 * @param WrappedComponent
 * @returns
 */
const withRole = (WrappedComponent: any) => {
  return function useWithRole(props: any) {
    const [newProps, setNewProps] = useState<any>(props);

    /**
     * Check the role of the logged user and and get props from PermissionsUI based on the priority of the role
     */
    useEffect(() => {
      if (!window.localStorage.getItem("roles")) {
        window.location.reload();
        return;
      }

      const userRolesJson: any = window.localStorage.getItem("roles");
      const userRoles: Array<string> = JSON.parse(userRolesJson)?.map((role: any) => role.name);

      //get props from PermissionsUI based on the priority of the role
      let updatedProps = props;
      userRoles.forEach((element: any, index: any) => {
        const ui = PermissionsUI[userRoles[index]];
        let uiProps;

        // if (ui) uiProps = ui[props.name];
        if (ui) uiProps = ui[props.name];

        //merge props from PermissionsUI with the props of the component
        updatedProps = deepMergeObject(updatedProps, uiProps);
      });

      // if (uiProps)
      setNewProps(updatedProps);
      // else setNewProps(null);
    }, []);

    /**
     *
     * @param initialObj
     * @param obj
     * @returns
     */
    function deepMergeObject(initialObj: any, obj: any) {
      const newObj = { ...initialObj };
      for (const key in obj) {
        const isArray = newObj[key] instanceof Array;
        if (
          typeof newObj[key] === "object" &&
          !isArray &&
          typeof obj[key] === "object" &&
          !moment.isMoment(obj[key])
        ) {
          newObj[key] = deepMergeObject(newObj[key], obj[key]);
        } else {
          if (isArray) {
            newObj[key] = compareArraysAndMerge(newObj[key], obj[key]);
          } else {
            newObj[key] = obj[key];
          }
        }
      }
      return newObj;
    }

    /**
     *
     * @param initialObj
     * @param obj
     */
    function compareArraysAndMerge(initialObj: any[], obj: any[]) {
      //create copy of the second array (in general bigger than the first one)
      const newArray = [...obj];
      //hash every element in both arrays
      const initialObjHash = initialObj.map((obj) => hash(obj));
      const objHash = obj.map((obj) => hash(obj));
      //check if the hash of the element in the first array is present in the second array
      const diff = initialObjHash.filter((obj) => !objHash.includes(obj));
      //if the hash is not present, add the element to the second array based on the index of the hash
      diff.forEach((hash) => {
        const index = initialObjHash.indexOf(hash);
        newArray.push(initialObj[index]);
      });
      return newArray;
    }

    return newProps ? <WrappedComponent {...newProps} /> : null;
  };
};

export default withRole;
