/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios";
import { HttpStatusCode, HttpVerbs } from "./Constants";
import { Vocabulary } from "./Vocabulary";
import { toast } from "react-toastify";
import { localUrlEnum } from "./UrlEnum";
import { logout } from "./Utils";

const notifyError = (message: string) => toast.error(message);
const notifySuccess = (message: string) => toast.success(message);

const token = localStorage.getItem("access_token");
let axiosConfig = {} as any;
if (token) {
  axiosConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
      "cache-control": "no-cache",
    },
  };
}

const request = axios.create(axiosConfig);

let requestNumber = 0;

request.interceptors.response.use(
  (response) => {
    if (
      response.status === HttpStatusCode.OK ||
      response.status === HttpStatusCode.Created
    ) {
      if (response.config.method === HttpVerbs.post) {
        if (response.config.url && response.config.url !== "/api/auth/login")
          notifySuccess(Vocabulary.createDataSuccess);
      }
      if (response.config.method === HttpVerbs.patch) {
        notifySuccess(Vocabulary.updateDataSuccess);
      }
      if (response.config.method === HttpVerbs.delete) {
        notifySuccess(Vocabulary.deleteDataSuccess);
      }
    }
    requestNumber--;
    if (requestNumber <= 0) {
      stopLoading();
    }
    return response;
  },
  (error) => {
    requestNumber--;
    switch (error.response.status) {
      case HttpStatusCode.Unauthorized:
      case HttpStatusCode.UnprocessableEntity:
        logout();
        window.location.href = localUrlEnum.login;
        break;
      case HttpStatusCode.BadRequest:
      case HttpStatusCode.InternalServerError:
        handleError(error);
        break;
      case HttpStatusCode.NotFound:
        if (error.response.data.message && error.response.data.message !== "")
          notifyError(error.response.data.message);
        else notifyError(Vocabulary.notFound);
        break;
      case HttpStatusCode.Forbidden:
        if (error.config.url && error.config.url === "/api/auth/login")
          notifyError(error.response.data.message);
        else {
          window.location.href = "/*";
        }
        break;
      default:
        notifyError(Vocabulary.error);
        break;
    }
    stopLoading();
  }
);

request.interceptors.request.use(
  (config) => {
    if (config.url?.includes("/api/orders/getEmails")) return config;
    requestNumber++;
    startLoading();
    return config;
  },
  (error) => {
    stopLoading();
  }
);


/**
 * Shows the loading animation
 * @function startLoading
 */
export function startLoading() {
  const element = document.getElementById("loading");
  element?.classList.remove("loadingDisabled");
  element?.classList.add("loading");
}


/**
 * Hides the loading animation
 * @function stopLoading
 */

export function stopLoading() {
  const element = document.getElementById("loading");
  element?.classList.remove("loading");
  element?.classList.add("loadingDisabled");
}


/**
 * Handles request errors by showing a toast notification with a message.
 * If the server error response contains a message, it is used.
 * Otherwise, a default message is used, depending on the HTTP method.
 */
function handleError(error: any) {
  if (error.response.data.message && error.response.data.message !== "")
    notifyError(error.response.data.message);
  else {
    if (error.config.method === HttpVerbs.get) {
      notifyError(Vocabulary.getDataError);
    }
    if (error.config.method === HttpVerbs.post) {
      notifyError(Vocabulary.createDataError);
    }
    if (error.config.method === HttpVerbs.patch) {
      notifyError(Vocabulary.updateDataError);
    }
    if (error.config.method === HttpVerbs.delete) {
      notifyError(Vocabulary.deleteDataError);
    }
  }
}

export default request;
