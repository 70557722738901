import { Divider } from "@mui/material";
import { CSSProperties, Fragment, useState } from "react";

export interface Props {
  data: any[];
  title: string;
  style?: CSSProperties;
  onOptionClick?: (status: any) => void;
}

export default function LegendComponent(props: Props) {
  const { data, title, style, onOptionClick } = props;
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null);

  const handleClick = (status: any) => {
    if (selectedStatus === status.displayName) {
      setSelectedStatus(null); // Set selected item by its unique property
      onOptionClick?.({ _id: "" });
    } else {
      setSelectedStatus(status.displayName); // Set selected item by its unique property
      onOptionClick?.(status); // Call the callback function if provided
    }
  };

  return (
    <div style={style}>
      <fieldset
        style={{
          border: "1px solid #0000001f",
          borderRadius: 10,
          marginTop: 20,
        }}
      >
        <legend
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "0rem 0.8rem",
          }}
        >
          {title}
        </legend>
        {data.map((status: any, key: any) => (
          <Fragment key={key}>
            <div
              onClick={() => handleClick(status)}
              style={{
                display: "flex",
                flexDirection: "column",
                cursor: "pointer",
                backgroundColor:
                  selectedStatus === status.displayName
                    ? "#dadbdd"
                    : "transparent",
              }}
            >
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    height: 30,
                    width: 30,
                    backgroundColor: status.color,
                    margin: 10,
                    borderRadius: 10,
                  }}
                ></div>
                <p
                  style={{
                    margin: 13,
                    verticalAlign: "center",
                    color: "#4d5e80",
                  }}
                >
                  {status.displayName}
                </p>
              </div>
            </div>
            {key !== data.length - 1 && <Divider />}
          </Fragment>
        ))}
      </fieldset>
    </div>
  );
}
