import { PictureAsPdf } from "@mui/icons-material";
import styles from "../Styles/dropzone.module.css";

export default function EmptyPdf() {
  return (
    <div className={styles.emptyPdfContainer}>
      <PictureAsPdf fontSize="large" color="primary" />
    </div>
  );
}
