import { genericService } from "../Services/genericService";
import { toast } from "react-toastify";
import { Vocabulary } from "../Utils/Vocabulary";
import { ApiResponse } from "../Types/ApiResponse";
const notifyError = (message: string) => toast.error(message);
const notifySuccess = (message: string) => toast.success(message);

/**
 * Provides a set of functions for performing CRUD operations on an entity of a given type.
 * @param entityType - The type of entity to be operated on.
 * @returns An object with methods for performing CRUD operations on the given entity type.
 */
export const useEntityActions = (entityType: string, token?: string) => {
  const service = genericService(entityType, token);

  /**
   * Retrieves all entities of the given type from the database.
   *
   * @returns A promise that resolves to an ApiResponse containing the retrieved entities.
   * @throws {Error} - If something goes wrong. The error message is the message from the server.
   */
  const getAll = async (page?: number, perPage?: number, filter?:string): Promise<ApiResponse> => {
    try {
      const response: ApiResponse = await service.getAll(page, perPage, filter);
      return response;
    } catch (error: any) {
      const message = error.response?.data?.message ? `${error.response.data.message}` : `${Vocabulary.getDataError}!`;
      notifyError(message);
      throw error;
    }
  };

  /**
   * Create a new entity in the database.
   * @param {object} entity - The entity to be created.
   * @returns {Promise<ApiResponse>} - The response from the server.
   * @throws {Error} - If something goes wrong. The error message is the message from the server.
   */
  const create = async (entity: any): Promise<ApiResponse> => {
    try {
      const response: ApiResponse = await service.create(entity);
      const message = response.message ? `${response.message}` : `${Vocabulary.createDataSuccess}!`;
      notifySuccess(message);
      return response;
    } catch (error: any) {
      const message = error.response?.data?.message ? `${error.response.data.message}` : `${Vocabulary.createDataError}!`;
      notifyError(message);
      throw error;
    }
  };

  /**
   * Updates an existing entity in the database.
   * @param {string | number} id - The id of the entity to be updated.
   * @param {object} entity - The entity to be updated.
   * @returns {Promise<ApiResponse>} - The response from the server.
   * @throws {Error} - If something goes wrong. The error message is the message from the server.
   */
  const update = async (id: string | number, entity: any): Promise<ApiResponse> => {
    try {
      const response: ApiResponse = await service.update(id.toString(), entity);
      const message = response.message ? `${response.message}` : `${Vocabulary.updateDataSuccess}!`;
      notifySuccess(message);
      return response;
    } catch (error: any) {
      const message = error.response?.data?.message ? `${error.response.data.message}` : `${Vocabulary.updateDataError}!`;
      notifyError(message);
      throw error;
    }
  };

  /**
   * Removes an existing entity in the database.
   * @param {string | number} id - The id of the entity to be removed.
   * @returns {Promise<ApiResponse>} - The response from the server.
   * @throws {Error} - If something goes wrong. The error message is the message from the server.
   */
  const remove = async (id: string | number): Promise<ApiResponse> => {
    try {
      const response: ApiResponse = await service.remove(id.toString());
      const message = response.message ? `${response.message}` : `${Vocabulary.deleteDataSuccess}!`;
      notifySuccess(message);
      return response;
    } catch (error: any) {
      const message = error.response?.data?.message ? `${error.response.data.message}` : `${Vocabulary.deleteDataError}!`;
      notifyError(message);
      throw error;
    }
  };

  /**
   * Retrieves the options for the entity.
   *
   * @returns A promise that resolves to the options of the entity.
   * @throws {Error} - If something goes wrong. The error message is the message from the server.
   */
  const options = async (): Promise<ApiResponse> => {
    try {
      const response: ApiResponse = await service.options();
      const message = response.message ? `${response.message}` : `${Vocabulary.getDataSuccess}!`;
      notifySuccess(message);
      return response;
    } catch (error: any) {
      const message = error.response?.data?.message ? `${error.response.data.message}` : `${Vocabulary.getDataError}!`;
      notifyError(message);
      throw error;
    }
  };

  return { getAll, create, update, remove, options };
};
