import Grid from "@mui/material/Grid2";
import { Controller } from "react-hook-form";
import { Vocabulary } from "../../Utils/Vocabulary";
import { FormEventHandler } from "react";
import {
  TextField,
  Autocomplete,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TableFooter,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

interface Product {
  name: string;
  totalPrice: number;
  quantity?: number; // Optional to allow initial rendering
  discount?: number; // Optional to allow initial rendering
}

interface AddOrderProps {
  control: any;
  selectedProducts: any[] | undefined;
  handleSubmit: (payload: any) => FormEventHandler<HTMLFormElement>;
  handleSubmitData: () => void;
  formData: any;
  handleDeleteProductFromOrder: (index: number) => void;
  products: any;
  statuses: any;
  users: any;
  mode: "create" | "edit" | "view" | "remove";
  getUsedDataFromServer: (phoneNumber: string) => void;
}

export default function AddOrder(props: AddOrderProps) {
  const {
    products,
    users,
    selectedProducts,
    control,
    statuses,
    handleSubmit,
    handleSubmitData,
    handleDeleteProductFromOrder,
    getUsedDataFromServer,
  } = props;

  const totalValue = selectedProducts?.reduce((sum, product) => {
    const priceAfterDiscount = product.totalPrice * product.quantity;
    return sum + priceAfterDiscount;
  }, 0);

  return (
    <Grid
      container
      spacing={2}
      sx={{ width: "80vw", height: "80vh", padding: "20px" }}
    >
      {/* Left Side: Form */}
      <Grid
        size={6}
        sx={{ display: "flex", flexDirection: "column", gap: "20px" }}
      >
        <form
          onSubmit={handleSubmit(handleSubmitData)}
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          {/* Autocomplete for Multi-selecting Products */}
          <Controller
            name="productLines"
            control={control}
            defaultValue={[]}
            render={({ field: { value, onChange } }) => (
              <Autocomplete
                multiple
                options={products}
                getOptionLabel={(option: any) => option.name}
                value={value}
                onChange={(event, newValue) => onChange(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Adaugă produse"
                    placeholder="Start typing to search..."
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            )}
          />

          {/* Phone Number Field */}
          <Controller
            name="phoneNumber"
            control={control}
            defaultValue=""
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <TextField
                value={value}
                onChange={(e) => {
                  onChange(e); // Update the form state
                  getUsedDataFromServer(e.target.value); // Update local state
                }}
                fullWidth
                label={Vocabulary.phoneNumber}
                variant="outlined"
                size="small"
                error={!!error}
                helperText={error?.message}
              />
            )}
          />

          {/* First Name Field */}
          <Controller
            name="firstName"
            control={control}
            defaultValue=""
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <TextField
                value={value}
                onChange={onChange}
                fullWidth
                label={Vocabulary.firstName}
                variant="outlined"
                size="small"
                error={!!error}
                helperText={error?.message}
              />
            )}
          />

          {/* Last Name Field */}
          <Controller
            name="lastName"
            control={control}
            defaultValue=""
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <TextField
                value={value}
                onChange={onChange}
                fullWidth
                label={Vocabulary.lastName}
                variant="outlined"
                size="small"
                error={!!error}
                helperText={error?.message}
              />
            )}
          />

          {/* Address Field */}
          <Controller
            name="address"
            control={control}
            defaultValue=""
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <TextField
                value={value}
                onChange={onChange}
                fullWidth
                label={Vocabulary.address}
                variant="outlined"
                size="small"
                error={!!error}
                helperText={error?.message}
              />
            )}
          />

          {/* Address Field */}
          <Controller
            name="reducereUtilizator"
            control={control}
            defaultValue=""
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <TextField
                value={value}
                onChange={onChange}
                fullWidth
                label="Reducere utilizator"
                variant="outlined"
                size="small"
                error={!!error}
                helperText={error?.message}
              />
            )}
          />

          {/* Address Field */}
          <Controller
            name="reducereComanda"
            control={control}
            defaultValue=""
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <TextField
                value={value}
                onChange={onChange}
                fullWidth
                label="Reducere comandă"
                variant="outlined"
                size="small"
                error={!!error}
                helperText={error?.message}
              />
            )}
          />

          <Controller
            name="status"
            control={control}
            defaultValue={statuses[0]._id}
            render={({
              field: { ref, onChange, value, ...field },
              fieldState: { error },
            }) => (
              <Autocomplete
                freeSolo={false}
                style={{
                  marginBottom: 15,
                }}
                {...field}
                size="small"
                disableClearable
                disablePortal
                getOptionLabel={(option: any) => option.name || ""}
                id="status"
                onChange={(event, newValue: any) => {
                  onChange(newValue ? newValue._id : "");
                }}
                value={
                  statuses?.find((option: any) => option?._id === value) || null
                }
                options={statuses}
                renderInput={(params) => (
                  <TextField
                    error={!!error}
                    helperText={error?.message}
                    label={Vocabulary.status}
                    name="status"
                    type="search"
                    inputRef={ref}
                    {...params}
                  />
                )}
              />
            )}
          />

          <Controller
            name="courier"
            control={control}
            defaultValue={null}
            render={({
              field: { ref, onChange, value, ...field },
              fieldState: { error },
            }) => (
              <Autocomplete
                freeSolo={false}
                style={{
                  marginBottom: 15,
                }}
                {...field}
                size="small"
                disableClearable
                disablePortal
                getOptionLabel={(option: any) =>
                  `${option.firstName} ${option.lastName}` || ""
                }
                id="courier"
                onChange={(event, newValue: any) => {
                  onChange(newValue ? newValue._id : "");
                }}
                value={
                  users?.find((option: any) => option?._id === value) || null
                }
                options={users.filter(
                  (user: any) => user.roleId.displayName === "Courier"
                )}
                renderInput={(params) => (
                  <TextField
                    error={!!error}
                    helperText={error?.message}
                    label={Vocabulary.courier}
                    name="courier"
                    type="search"
                    inputRef={ref}
                    {...params}
                  />
                )}
              />
            )}
          />

          {/* Submit Button */}
          <Button variant="contained" color="primary" type="submit">
            {Vocabulary.save}
          </Button>
        </form>
      </Grid>

      {/* Right Side: List of Products */}
      <Grid size={6}>
        <Paper sx={{ maxHeight: "70vh", overflow: "auto" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{Vocabulary.product}</TableCell>
                <TableCell>{Vocabulary.quantity}</TableCell>
                <TableCell>{Vocabulary.discount}</TableCell>
                <TableCell>{Vocabulary.price}</TableCell>
                <TableCell>{Vocabulary.delete}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedProducts?.map((product: Product, index: number) => (
                <TableRow key={index}>
                  <TableCell>{product.name}</TableCell>
                  {/* Quantity Field */}
                  <TableCell>
                    <Controller
                      name={`productLines.${index}.quantity`}
                      control={control}
                      defaultValue={1}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => (
                        <TextField
                          type="number"
                          value={value}
                          onChange={onChange}
                          fullWidth
                          label="Cantitate"
                          variant="outlined"
                          size="small"
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  </TableCell>
                  <TableCell>
                    <Controller
                      name={`productLines.${index}.discount`}
                      control={control}
                      defaultValue={0}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => (
                        <TextField
                          type="number"
                          value={value}
                          onChange={onChange}
                          fullWidth
                          label="Reducere"
                          variant="outlined"
                          size="small"
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  </TableCell>
                  <TableCell>{product.totalPrice}</TableCell>
                  <TableCell>
                    <DeleteIcon
                      style={{
                        height: 30,
                        cursor: "pointer",
                      }}
                      onClick={(event: any) => {
                        event.stopPropagation();
                        handleDeleteProductFromOrder(index);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={3} align="right">
                  <strong>Total:</strong>
                </TableCell>
                <TableCell colSpan={2}>
                  <strong>{totalValue?.toFixed(2)} lei</strong>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </Paper>
      </Grid>
    </Grid>
  );
}
