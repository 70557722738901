import axios from "axios";
import Config from "../Utils/Config";
import { ApiResponse } from "../Types/ApiResponse";
import { Vocabulary } from "../Utils/Vocabulary";
const API_URL = Config.apiUrl;

/**
 * Service for performing CRUD operations on a given entity type.
 *
 * @param entityType - The type of entity to be operated on.
 * @returns An object with methods for performing CRUD operations on the given entity type.
 */
export const genericService = (entityType: string, token?: string) => {
  let endpoint = `${API_URL}/${entityType}`;

  const getAll = (
    page?: number,
    perPage?: number,
    filter?: string
  ): Promise<ApiResponse> => {
    const pageNumber = page ?? 1;
    const perPageNumber = perPage ?? 10;
    if (page!==undefined && page!==null && perPage) {
      endpoint += `?page=${pageNumber}&perPage=${perPageNumber}`;

      if (filter) {
        endpoint += `&filter=${filter}`;
      }
    }
    return new Promise((resolve, reject) => {
      axios
        .get(endpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => resolve(response.data as ApiResponse))
        .catch((error) => reject(error));
    });
  };

  /**
   * Gets an entity by id.
   *
   * @param id - The id of the entity to retrieve.
   * @returns A promise that resolves to the retrieved entity.
   */
  const get = (id: string): Promise<ApiResponse> => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${endpoint}/${id}`)
        .then((response) => resolve(response.data as ApiResponse))
        .catch((error) => reject(error));
    });
  };

  /**
   * Creates a new entity with the given data.
   *
   * @param data - The data of the entity to create.
   * @returns A promise that resolves to the created entity.
   */
  const create = (data: any): Promise<ApiResponse> => {
    if (data?.images) {
      return createWithFiles(data);
    }
    return new Promise((resolve, reject) => {
      axios
        .post(endpoint, data)
        .then((response) => resolve(response.data as ApiResponse))
        .catch((error) => reject(error));
    });
  };

  /**
   * Creates a new entity with the given data, including files.
   *
   * @param data - The data of the entity to create, including files to be uploaded.
   * @returns A promise that resolves to the created entity.
   */
  const createWithFiles = (data: any): Promise<ApiResponse> => {
    if (!data) throw new Error(Vocabulary.dataIsRequired);
    const formData = new FormData();
    if (data.files && data.files.length !== 0)
      data.files.forEach((file: any) => {
        formData.append("files", file, file.name);
      });
    else formData.append("files", [] as any);
    data.images = data.images.filter((img: any) => typeof img === "string");
    data.files = [];
    formData.append(entityType.toString(), JSON.stringify(data));
    //end form data
    return new Promise((resolve, reject) => {
      axios
        .post(endpoint, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => resolve(response.data as ApiResponse))
        .catch((error) => reject(error));
    });
  };
  /**
   * Updates an existing entity with the given id and data.
   *
   * @param id - The id of the entity to update.
   * @param data - The data to update the entity with.
   * @returns A promise that resolves to the updated entity.
   */
  const update = (id: string, data: any): Promise<ApiResponse> => {
    if (data?.images) {
      return updateWithFiles(id, data);
    }
    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoint}/${id}`, data)
        .then((response) => resolve(response.data as ApiResponse))
        .catch((error) => reject(error));
    });
  };

  /**
   * Updates an existing entity with the given id and data, including files.
   *
   * @param data - The data to update the entity with.
   * @returns A promise that resolves to the updated entity.
   */
  const updateWithFiles = (id: string, data: any): Promise<ApiResponse> => {
    if (!data) throw new Error(Vocabulary.dataIsRequired);
    const formData = new FormData();
    if (data.files && data.files.length !== 0)
      data.files.forEach((file: any) => {
        formData.append("files", file, file.name);
      });
    else formData.append("files", [] as any);
    data.images = data.images.filter((img: any) => typeof img === "string");
    data.files = [];
    formData.append(entityType.toString(), JSON.stringify(data));
    //end form data
    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoint}/${id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => resolve(response.data as ApiResponse))
        .catch((error) => reject(error));
    });
  };

  /**
   * Removes an existing entity with the given id.
   *
   * @param id - The id of the entity to remove.
   * @returns A promise that resolves to the removed entity.
   */
  const remove = (id: string): Promise<ApiResponse> => {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${endpoint}/${id}`)
        .then((response) => resolve(response.data as ApiResponse))
        .catch((error) => reject(error));
    });
  };

  /**
   * Retrieves the options for the entity.
   *
   * @returns A promise that resolves to the entity options.
   */
  const options = (): Promise<ApiResponse> => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${endpoint}/options`)
        .then((response) => resolve(response.data as ApiResponse))
        .catch((error) => reject(error));
    });
  };

  return {
    getAll,
    get,
    create,
    createWithFiles,
    update,
    updateWithFiles,
    remove,
    options,
  };
};
