import * as Yup from "yup";
import { Vocabulary } from "../Utils/Vocabulary";

export const UserModel = Yup.object().shape({
  _id: Yup.string(),
  firstName: Yup.string().required(Vocabulary.required),
  lastName: Yup.string().required(Vocabulary.required),
  address: Yup.string(),
  phoneNumber: Yup.string().required(Vocabulary.required),
  email: Yup.string().required(Vocabulary.required),
  username: Yup.string().required(Vocabulary.required),
  password: Yup.string().required(Vocabulary.required),
  confirmPassword: Yup.string().required(Vocabulary.required),
  status: Yup.string(),
  roleId: Yup.string(),
  isActive: Yup.boolean().default(true),
});
