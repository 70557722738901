import Grid from "@mui/material/Grid2";
import { Vocabulary } from "../../Utils/Vocabulary";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TableFooter,
} from "@mui/material";

interface Product {
  name: string;
  totalPrice: number;
  quantity?: number; // Optional to allow initial rendering
  discount?: number; // Optional to allow initial rendering
}

interface OrderLinesProps {
  selectedProducts: any | undefined;
}

export default function OrderLines(props: OrderLinesProps) {
  const { selectedProducts } = props;

  const totalValue = selectedProducts.productLines?.reduce(
    (sum: any, product: any) => {
      const priceAfterDiscount = product.totalPrice * product.quantity;
      return sum + priceAfterDiscount;
    },
    0
  );

  return (
    <Grid
      container
      spacing={2}
      sx={{ width: "80vw", height: "30vh", padding: "20px" }}
    >
      {/* Right Side: List of Products */}
      <Grid size={6} sx={{ width: "inherit" }}>
        <Paper sx={{ maxHeight: "30vh", overflow: "auto" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{Vocabulary.product}</TableCell>
                <TableCell>{Vocabulary.quantity}</TableCell>
                <TableCell>{Vocabulary.discount}</TableCell>
                <TableCell>{Vocabulary.price}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedProducts?.productLines?.map(
                (product: Product, index: number) => (
                  <TableRow key={index}>
                    <TableCell>{product.name}</TableCell>
                    {/* Quantity Field */}
                    <TableCell>
                      <strong>{product.quantity || 0}</strong>
                    </TableCell>
                    <TableCell>
                      <strong>{product.discount || 0}</strong>
                    </TableCell>
                    <TableCell>{product.totalPrice}</TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={3} align="right">
                  <strong>Total:</strong>
                </TableCell>
                <TableCell colSpan={2}>
                  <strong>{totalValue?.toFixed(2)} lei</strong>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </Paper>
      </Grid>
    </Grid>
  );
}
