import * as React from "react";
import { Card, CardMedia, Box, IconButton } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

interface CarouselProps {
  imageLinks: string[];
}

export default function PreviewCard(imageLink: CarouselProps) {
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const { imageLinks } = imageLink;

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === imageLinks.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? imageLinks.length - 1 : prevIndex - 1
    );
  };

  return (
    <Box sx={{ position: "relative", maxWidth: "100%", overflow: "hidden" }}>
      <Box
        sx={{
          display: "flex",
          transform: `translateX(-${currentIndex * 100}%)`,
          transition: "transform 0.5s ease",
        }}
      >
        {imageLinks?.map((link, index) => (
          <Card key={index} sx={{ minWidth: "100%", boxShadow: "none" }}>
            <CardMedia
              component="img"
              height="400"
              image={link}
              alt={`Carousel image ${index + 1}`}
              sx={{ objectFit: "cover", width: "100%" }}
            />
          </Card>
        ))}
      </Box>
      <IconButton
        onClick={handlePrev}
        sx={{
          position: "absolute",
          top: "50%",
          left: 0,
          transform: "translateY(-50%)",
          zIndex: 1,
          backgroundColor: "white",
          "&:hover": { backgroundColor: "white" },
        }}
      >
        <ArrowBackIosIcon />
      </IconButton>
      <IconButton
        onClick={handleNext}
        sx={{
          position: "absolute",
          top: "50%",
          right: 0,
          transform: "translateY(-50%)",
          zIndex: 1,
          backgroundColor: "white",
          "&:hover": { backgroundColor: "white" },
        }}
      >
        <ArrowForwardIosIcon />
      </IconButton>
    </Box>
  );
}
