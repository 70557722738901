/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
import MenuIcon from "@mui/icons-material/Menu";
import { Fab, Typography } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import style from "./Styles/mainHeader.module.css";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import Logout from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { isMobile } from "react-device-detect";
import withRole from "../../Utils/withRole";
import { postData } from "../../Services/postData";
import { Vocabulary } from "../../Utils/Vocabulary";
import { urlEnum } from "../../Utils/UrlEnum";

function MainHeader(props: any) {
  const username = localStorage.getItem("userName");
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  useEffect(() => {
    const userId = localStorage.getItem("useId");
    (window as any).authenticatedUserIdAutodel = userId;
  }, []);

  /**
   * set anchor el for menu
   * @param event
   */
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * close menu
   */
  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   * logout
   */
  function logout() {
    postData(`${urlEnum.logout}`).then(() => {
      setAnchorEl(null);
      localStorage.setItem("useId", "");
      localStorage.setItem("userName", "");
      localStorage.setItem("userEmail", "");
      localStorage.setItem("access_token", "");
      localStorage.setItem("roles", "");
      document.cookie = "accept_notification =; ";
      navigate("/login");
      window.location.reload();
    });
  }

  return (
    <div className={style.container}>
      <div style={{ display: "flex" }}>
        {isMobile ? (
          <div>
            <IconButton
              onClick={props.handleFilter}
              style={{ backgroundColor: "#fff" }}
              aria-label="add"
            >
              {props.hideSlider ? <MenuIcon /> : <CloseIcon></CloseIcon>}
            </IconButton>
          </div>
        ) : (
          <img src="/casaVatraLogoRotund.svg" alt="No file" style={{ marginLeft: 5 }} />
        )}

        <div>
          <Typography className={style.title}>{props.title}</Typography>
        </div>
      </div>
      <div style={{ display: "flex" }}>
        <Typography className={style.username}>{username}</Typography>
        <Fab
          onClick={handleClick}
          size="small"
          style={{ backgroundColor: "#fff", color: "#4D5E80" }}
          aria-label="add"
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <AccountCircleIcon />
        </Fab>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem onClick={handleClose}>
            <Avatar /> {isMobile ? username : Vocabulary.myAccount}
          </MenuItem>
          <Divider />
          <MenuItem onClick={logout}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
}

export default withRole(MainHeader);
