import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import { styled } from "@mui/system";

// Styled component to make the dialog background transparent gray
const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiBackdrop-root": {
    backgroundColor: "rgba(0, 0, 0, 0.5)", // transparent gray
  },
}));

type genericDialogPropsType = {
  open: boolean;
  title: string;
  message: string;
  onClose: ()=>void;
  onOk: ()=>void;
  onCancel: ()=>void;
};

export default function GenericDialog(
  genericDialogProps: genericDialogPropsType
) {
  const { open, title, message, onClose, onOk, onCancel } = genericDialogProps;

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={onOk} color="primary" autoFocus>
          OK
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}
