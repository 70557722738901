import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { useEffect, useState } from "react";
import type { Router } from "@remix-run/router";
import { localUrlEnum } from "./UrlEnum";
import Login from "../components/Login";
import ProductList from "../components/Products/ProductList";
import withRole from "./withRole";
import HeaderAndSlider from "../components/Generic/HeaderAndSlider";
import CategoriesList from "../components/Categories/CategoriesList";
import OrdersList from "../components/Orders/OrdersList";
import { Settings } from "@mui/icons-material";
import { Vocabulary } from "./Vocabulary";
import UsersList from "../components/Users/UsersList";

type LocalRouterProps = {
  routes: Array<string> | undefined;
};

function LocalRouter(props: LocalRouterProps) {
  const routerPaths = [
    {
      path: "/",
      element: <Login />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: localUrlEnum.orders,
      element: addHeaderAndMenu(<OrdersList />, Vocabulary.orders),
    },
    {
      path: localUrlEnum.products,
      element: addHeaderAndMenu(<ProductList />, Vocabulary.products),
    },
    {
      path: localUrlEnum.categories,
      element: addHeaderAndMenu(<CategoriesList />, Vocabulary.categories),
    },
    {
      path: localUrlEnum.users,
      element: addHeaderAndMenu(<UsersList />, Vocabulary.users),
    },
    {
      path: localUrlEnum.settings,
      element: addHeaderAndMenu(<Settings />, Vocabulary.settings),
    }
  ];

  const [router, setRouter] = useState<Router>();

  useEffect(() => {
    if (!props.routes) return;

    const newRouterPaths = routerPaths.filter((crtRoute) => {
      return props.routes && props.routes.indexOf(crtRoute.path) >= 0;
    });
    setRouter(createBrowserRouter(newRouterPaths));
  }, [props]);

  function addHeaderAndMenu(component: any, title: string) {
    return <HeaderAndSlider key={title} title={title} component={component} />;
  }

  return router && <RouterProvider router={router} />;
}

export default withRole(LocalRouter);
