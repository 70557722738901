import React, { useState } from "react";
import styles from "../Styles/loginPage.module.css";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { localUrlEnum, urlEnum } from "../Utils/UrlEnum";
import { useNavigate } from "react-router-dom";
import { Vocabulary } from "../Utils/Vocabulary";
import { postData } from "../Services/postData";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { LoginModel } from "../Models/LoginModel";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export default function Login() {
  const { handleSubmit, control } = useForm({
    resolver: yupResolver(LoginModel),
  });
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  // Function to toggle password visibility
  const handleClickShowPassword = () =>
    setShowPassword((prev: boolean) => !prev);

  // Function to prevent mouse down default behavior
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => event.preventDefault();

  /**
   *
   */
  async function handleUserDataSubmit(payload: any) {
    // eslint-disable-next-line no-console
    const response = await postData(urlEnum.login, payload);
    if (response && response.data) {
      localStorage.setItem("useId", response.data.userId);
      localStorage.setItem("userName", response.data.userName);
      localStorage.setItem("userEmail", response.data.email);
      localStorage.setItem("access_token", response.data.access_token);

      const roles: any = [];
      roles.push(response.data.roleId);
      localStorage.setItem("roles", JSON.stringify(roles));

      navigate(localUrlEnum.orders);
      window.location.reload();
    }
  }

  const onError = (model: any) => {
    //
  };

  return (
    <div className={styles.registerContainer}>
      <div className={styles.imageContainer}>
        <img src="/casaVatraSiglaAlb.svg" alt="CASAVATRA Img" />
      </div>
      <div className={styles.formContainer}>
        <Grid
          container
          direction="column"
          justifyContent="space-between"
          alignItems="center"
          sx={{ minHeight: "100vh" }}
        >
          <Grid
            component="div"
            sx={{
              flex: 2,
              mt: 8,
              mb: 0,
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <h1>{Vocabulary.login}</h1>
          </Grid>
          <Grid component="div" sx={{ flex: 4, mt: 8, mb: 2, width: "100%" }}>
            <form
              onSubmit={handleSubmit(handleUserDataSubmit, onError)}
              method="post"
              id="checkUserData"
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              <Controller
                name="username"
                defaultValue=""
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <TextField
                    onChange={onChange}
                    fullWidth
                    style={{
                      marginBottom: 15,
                    }}
                    value={value}
                    error={!!error}
                    helperText={error?.message}
                    id="username"
                    label={Vocabulary.username}
                    size="small"
                    variant="outlined"
                  />
                )}
              />
              <Controller
                name="password"
                defaultValue=""
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <TextField
                    onChange={onChange}
                    fullWidth
                    style={{
                      marginBottom: 15,
                    }}
                    value={value}
                    error={!!error}
                    helperText={error?.message}
                    id="password"
                    label={Vocabulary.password}
                    size="small"
                    variant="outlined"
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              <Button variant="contained" type="submit">
                {Vocabulary.login}
              </Button>
            </form>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
