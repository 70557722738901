/* eslint-disable camelcase */
import { Vocabulary } from "./Vocabulary";

///
export const internalLocationArray = ["CUSTOMER", "IN TRANSIT", "IN SERVICE"];

///
export const notesVisibility = {
  hidden: "HIDDEN",
  visible: "VISIBLE",
  deleted: "DELETED",
};

///
export const notesVisibilityArray = [
  {
    label: "Internal",
    name: "HIDDEN",
  },
  { label: "Public", name: "VISIBLE" },
  { label: "Removed", name: "DELETED" },
];

///
export const notesType = {
  auftragAngelegt: "Auftrag angelegt",
  auftragFehlerbeschreib: "Auftrag / Fehlerbeschreib",
  issue: "ISSUE",
  diagnosis: "DIAGNOSIS",
  resolution: "RESOLUTION",
  internal: "INTERNAL",
  email: "EMAIL",
  sms: "SMS",
  detail: "DETAIL",
  other: "OTHER",
};

///
export const notesTypeArray = [
  "Auftrag angelegt",
  "Auftrag / Fehlerbeschreib",
  "ISSUE",
  "DIAGNOSIS",
  "RESOLUTION",
  "INTERNAL",
  "EMAIL",
  "SMS",
  "DETAIL",
  "OTHER",
];

///
export const defaultNote_OrderCreated = {
  _id: null,
  type: notesType.auftragAngelegt,
  title: "Auftrag / Fehlerbeschreib",
  text: "",
  status: 0,
  visibility: notesVisibility.hidden,
  report: 0,
  createdBy: null,
  createdAt: new Date(),
};

///
export const FilesTypes = {
  pdf: "application/pdf",
  png: "image/png",
  jpeg: "image/jpeg",
  jpg: "image/jpg",
  doc: "application/msword",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
};

///
export const stepTypes = {
  password: "PASSWORD",
  boolean: "BOOL",
  text: "TEXT",
  multiselect: "SELECT",
};

///
export const booleanTypes = {
  yes: "YES",
  no: "NO",
};

///
export const booleanTypesArray = [
  {
    text: "YES",
  },
  {
    text: "NO",
  },
];

///
export const usersTypes = {
  employees: "employees",
  customers: "customers",
  vendors: "vendors",
};

///
export const stocksManagementTypes = {
  inventories: "inventories",
  transfers: "transfers",
  incomingGoods: "incomingGoods",
  receivedGoods: "receivedGoods",
};

///
export const templatesTabs = ["emailTemplates", "documentsTemplates"];
export const templatesTypes = {
  emailTemplates: "emailTemplates",
  documentsTemplates: "documentsTemplates",
};

///
export const toggleTimeTracking = [
  {
    name: Vocabulary.takePredefinedCost,
    value: "takePredefinedCost",
  },
  {
    name: Vocabulary.timeTracking,
    value: "timeTracking",
  },
];

///
export const additionalInformationForVariants = {
  price: "price",
  inventory_quantity: "inventory_quantity",
};

///
export const TemplatesType = [
  { id: 1, name: "Estimate" },
  { id: 2, name: "Invoice" },
];

export const OrderTypes = {
  ESTIMATE: "ESTIMATE",
  ORDER: "ORDER",
};

///
export const StockTransferStatuses = {
  inProgress: "In progress",
  inTransit: "In transit",
  received: "Received",
  canceled: "Canceled",
  outtake: "Outtake",
};

export const StockTransferStatusesAutocomplete = [
  "In progress",
  "In transit",
  "Received",
  "Canceled",
  "Outtake",
];

export const StockTransferStatusesAutocompleteWithoutOuttake = [
  "In progress",
  "In transit",
  "Received",
  "Canceled",
];

///
export const PurchaseOrderStatuses = {
  open: "Open",
  closed: "Closed",
};

/**
 *
 */
export const ShowGoodsType = {
  purchaseOrder: "purchaseOrder",
  inventory: "inventory",
  product: "product",
};

/**
 *
 */
export const locationName = "upVolt GmbH";

/**
 *
 */
export const shipmentStatuses = {
  pending: "pending",
  inTransit: "inTransit",
  delivered: "delivered",
  inbound: "inbound",
  outbound: "outbound",
};

export const checkInSteps = [
  "Customer",
  "Additional information",
  "Device",
  "Workflow",
  "Issue description",
];

export const productTypes = {
  material: "Material",
  totalDevice: "Total device",
  excess: "Excess",
  product: "Product",
  service: "Service",
  bundle: "Bundle",
  transport: "Transport",
  replacement: "Replacement",
  other: "Other",
  module: "Module",
};

export const productTypesArray = [
  "Material",
  "Total device",
  "Excess",
  "Product",
  "Service",
  "Bundle",
  "Transport",
  "Replacement",
  "Other",
  "Module",
];

/**
 *
 */
export const settingsMenuEnum = {
  taxRates: "taxRates",
  emailSettings: "emailSettings",
};

export const productSettings = {
  attributes: "attributes",
  categories: "categories",
};

export const otherReports = {
  devicesReports: "devices",
  productsReports: "products",
  technicianEfficiency: "users",
};
