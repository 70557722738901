import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import { Vocabulary } from "../Utils/Vocabulary";
import { localUrlEnum } from "../Utils/UrlEnum";

export const ROUTER_DASHBOARD = {
  routes: ["/", "/login",localUrlEnum.users, "*"],
};

export const MAIN_SLIDER_USERS = [
  {
    name: Vocabulary.users,
    path: localUrlEnum.users,
    icon: <DirectionsCarIcon />,
    orderId: 0,
  },
];
