/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Checkbox, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Vocabulary } from "../../Utils/Vocabulary";
import { CloudUpload } from "@mui/icons-material";
import DropzoneComponent from "./DropzoneComponent";
import styles from "../../Styles/showImages.module.css";
import { useState } from "react";
import theme from "../../Themes/Theme";

type AddAndShowImagesProps = {
  mode: 'create' | 'edit' | 'view' | 'remove';
  images: string[];
  files: any;
  checkedImages?: any;
  isSmall?: boolean;
  handleDeleteImages: (
    newImageList: any,
    newFileList: any,
    index: number
  ) => void;
  handleChangeFiles: (files: any) => void;
  displayDropzoneImages: (images: any) => void;
  handleCheckImages?: (index: number) => void;
};

/**
 * A component that allows users to attach images and shows them in a
 * container. It also allows users to check some images and delete them.
 *
 * @param {string[]} images The list of images to be shown.
 * @param {any} files The list of files to be shown.
 * @param {any} checkedImages The list of checked images.
 * @param {boolean} isSmall Whether the images should be shown in small size.
 * @param {(newImageList: any, newFileList: any, index: number) => void} handleDeleteImages
 * A function that is called when the user deletes an image.
 * @param {(files: any) => void} handleChangeFiles A function that is called
 * when the user selects new files.
 * @param {(images: any) => void} displayDropzoneImages A function that is
 * called when the user finishes selecting new images.
 * @param {(index: number) => void} [handleCheckImages] A function that is
 * called when the user checks or unchecks an image.
 */
export default function AddAndShowImages(props: AddAndShowImagesProps) {
  const {
    images,
    files,
    checkedImages,
    isSmall,
    handleDeleteImages,
    handleChangeFiles,
    displayDropzoneImages,
    handleCheckImages,
    mode
  } = props;
  const [showDropzone, setShowDropzone] = useState<boolean>(false);
  const [dropzoneFiles, setDropzoneFiles] = useState<any>([]);

  /**
   * When user selects files from the drop zone, this function is called.
   * It reads the selected files and converts them to a data URL,
   * and then calls the parent's handleChangeFiles and sets the dropzoneFiles state with the new data URL.
   * @param {any} files the files selected by the user
   */
  function handleChangeDropFile(files: any) {
    if (files.length !== 0) {
      handleChangeFiles(files);
      const images = [] as any;
      files.forEach((file: any) => {
        const reader = new FileReader();
        if (file.type.match("image.*")) {
          reader.readAsDataURL(file);
          reader.onloadend = function () {
            images.push({
              path: reader.result,
              name: file.name,
            });
            setDropzoneFiles(images);
          };
        }
      });
    }
  }

  /**
   * When user clicks on the delete button on an image, this function is called.
   * It creates a new list of images and a new list of files, by removing the image and file at the given index.
   * It then calls the parent's handleDeleteImages with the new lists.
   * @param {number} index the index of the image to delete
   */
  function deleteImage(index: any) {
    const newImageList = images.slice() as any;
    let newFileList = [];
    if (files) {
      newFileList = files.slice() as any;
      const fileIndex = newFileList.findIndex(
        (file: any) => file.name === newImageList[index].name
      );
      if (fileIndex >= 0) newFileList.splice(fileIndex, 1);
    }
    newImageList.splice(index, 1);
    handleDeleteImages(newImageList, newFileList, index);
  }


  /**
   * Function called when user clicks on the show images button.
   * It appends the images that have been uploaded to the dropzone to the images list,
   * and then resets the dropzoneFiles state and the showDropzone state.
   */
  function showImages() {
    displayDropzoneImages([...images, ...dropzoneFiles]);
    setDropzoneFiles([]);
    setShowDropzone(false);
  }
  return (
    <>
      {showDropzone ? (
        <div style={{ marginTop: 20, width: "100%" }}>
          <DropzoneComponent
            onSave={(files: any) => handleChangeDropFile(files)}
          />
          <Button
            variant="contained"
            size="large"
            className={styles.showImagesBtn}
            onClick={showImages}
            fullWidth
            color="success"
            disabled={mode === "view"}
          >
            {Vocabulary.finish}
          </Button>
        </div>
      ) : (
        <>
          <Button
            variant="contained"
            size="medium"
            style={{ marginTop: 20, marginBottom: 10, width: "100%" }}
            onClick={() => setShowDropzone(true)}
            color="success"
            disabled={mode === "view"}

          >
            <CloudUpload
              fontSize="medium"
              style={{
                marginRight: 10,
              }}
            />
            {Vocabulary.attachFiles}
          </Button>
          <div>
            <div className={styles.displayImagesContainer}>
              {images.map((image: any, index: any) => {
                return (
                  <div
                    key={`image_${index}`}
                    className={
                      isSmall ? styles.displayImagesSmall : styles.displayImages
                    }
                  >
                    {handleCheckImages && handleCheckImages ? (
                      <Checkbox
                        checked={checkedImages.includes(index) ? true : false}
                        onChange={() => handleCheckImages(index)}
                        className={styles.checkboxImagesBtn}
                        style={{ color: theme.palette.primary.main }}
                      />
                    ) : null}
                    <IconButton
                      className={styles.deleteImagesBtn}
                      onClick={() => deleteImage(index)}
                      disabled={mode === "view"}
                    >
                      <DeleteIcon />
                    </IconButton>
                    <img
                      src={image.path ? image.path : image}
                      alt="preview"
                      style={{ height: "100%" }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
    </>
  );
}
