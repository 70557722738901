/* eslint-disable @typescript-eslint/no-explicit-any */

export function logout() {
  localStorage.removeItem("userId");
  localStorage.removeItem("lastName");
  localStorage.removeItem("userEmail");
  localStorage.removeItem("userName");
  localStorage.removeItem("access_token");
  localStorage.removeItem("cache");
}

export const FilesTypes = {
  pdf: "application/pdf",
  png: "image/png",
  jpeg: "image/jpeg",
  jpg: "image/jpg",
  doc: "application/msword",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
};

export const checkIfSrcBelongsToPdf = (src: string) => {
  return src.endsWith(".pdf");
};

export function createFormData(data: Record<string, any>): FormData {
  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    const value = data[key];

    if (Array.isArray(value)) {
      // If the value is an array, append each item individually
      value.forEach((item) => {
        if (item instanceof File) {
          // If the item is a File, append it directly
          formData.append(key, item);
        } else {
          // If the item is not a File, append it as a string
          formData.append(key, item.toString());
        }
      });
    } else if (value instanceof File) {
      // If the value is a single File, append it directly
      formData.append(key, value);
    } else if (typeof value === "object" && value !== null) {
      // If the value is an object, stringify it (for JSON or complex structures)
      formData.append(key, JSON.stringify(value));
    } else {
      // For any other types (strings, numbers, etc.), append as a string
      formData.append(key, value?.toString() || "");
    }
  });

  return formData;
}

