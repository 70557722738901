import moment from "moment";

export const europeanDate = "DD/MM/YYYY";
export const europeanDateWithTime = "DD/MM/YYYY hh:mm:ss";
export const shortEuropeanDate = "MM YYYY";

export const usDateFormat = "YYYY/MM/DD";
export const usDateFormatWithTime = "YYYY/MM/DD hh:mm:ss";

export default function formatDate(data: any) {
  return moment(data, europeanDate, true).format();
}

export function formatNumber(number: any) {
  return number?.toLocaleString("ro-Ro");
}
