import { Autocomplete, TextField } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Controller } from "react-hook-form";
import { Vocabulary } from "../../Utils/Vocabulary";
import { FormEventHandler } from "react";

interface UserFormProps {
  control: any;
  roles: any;
  handleSubmit: (payload: any) => FormEventHandler<HTMLFormElement>;
  handleSubmitData: () => void;
}
export default function UserForm(props: UserFormProps) {
  const { control, roles, handleSubmit, handleSubmitData } = props;

  return (
    <Grid sx={{ flex: 2, mt: 4, mb: 2 }}>
      <form
        onSubmit={handleSubmit(handleSubmitData)}
        method="get"
        id="checkUserData"
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <Controller
          name="firstName"
          defaultValue=""
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <TextField
              onChange={onChange}
              fullWidth
              style={{
                marginBottom: 15,
              }}
              value={value}
              error={!!error}
              helperText={error?.message}
              id="firstName"
              label={Vocabulary.firstName}
              size="small"
              variant="outlined"
            />
          )}
        />
        <Controller
          name="lastName"
          defaultValue=""
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <TextField
              onChange={onChange}
              fullWidth
              style={{
                marginBottom: 15,
              }}
              value={value}
              error={!!error}
              helperText={error?.message}
              id="lastName"
              label={Vocabulary.lastName}
              size="small"
              variant="outlined"
            />
          )}
        />
        <Controller
          name="username"
          defaultValue=""
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <TextField
              onChange={onChange}
              fullWidth
              style={{
                marginBottom: 15,
              }}
              value={value}
              error={!!error}
              helperText={error?.message}
              id="username"
              label={Vocabulary.username}
              size="small"
              variant="outlined"
            />
          )}
        />
        <Controller
          name="phoneNumber"
          defaultValue=""
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <TextField
              onChange={onChange}
              fullWidth
              style={{
                marginBottom: 15,
              }}
              value={value}
              error={!!error}
              helperText={error?.message}
              id="phoneNumber"
              label={Vocabulary.phoneNumber}
              size="small"
              variant="outlined"
            />
          )}
        />
        <Controller
          name="email"
          defaultValue=""
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <TextField
              onChange={onChange}
              fullWidth
              style={{
                marginBottom: 15,
              }}
              value={value}
              error={!!error}
              helperText={error?.message}
              id="email"
              label={Vocabulary.email}
              size="small"
              variant="outlined"
            />
          )}
        />
        <Controller
          name="password"
          defaultValue=""
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <TextField
              onChange={onChange}
              fullWidth
              style={{
                marginBottom: 15,
              }}
              value={value}
              error={!!error}
              helperText={error?.message}
              id="password"
              label={Vocabulary.password}
              size="small"
              variant="outlined"
            />
          )}
        />
        <Controller
          name="confirmPassword"
          defaultValue=""
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <TextField
              onChange={onChange}
              fullWidth
              style={{
                marginBottom: 15,
              }}
              value={value}
              error={!!error}
              helperText={error?.message}
              id="confirmPassword"
              label={Vocabulary.confirmPassword}
              size="small"
              variant="outlined"
            />
          )}
        />

        <Controller
          name="roleId"
          control={control}
          defaultValue={roles[0]._id}
          render={({
            field: { ref, onChange, value, ...field },
            fieldState: { error },
          }) => (
            <Autocomplete
              freeSolo={false}
              style={{
                marginBottom: 15,
              }}
              {...field}
              size="small"
              disableClearable
              disablePortal
              getOptionLabel={(option: any) => option.displayName || ""}
              id="status"
              onChange={(event, newValue: any) => {
                onChange(newValue ? newValue._id : "");
              }}
              value={
                roles?.find((option: any) => option?._id === value) || null
              }
              options={roles}
              renderInput={(params) => (
                <TextField
                  error={!!error}
                  helperText={error?.message}
                  label={Vocabulary.roles}
                  name="status"
                  type="search"
                  inputRef={ref}
                  {...params}
                />
              )}
            />
          )}
        />
      </form>
    </Grid>
  );
}
